.page {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.carousel-container {
  position: relative;
  height: calc(80vh / 100 * 100vw);
  overflow: hidden;
}

.carousel .carousel-slider {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: 40%; /* Adjust the value as needed */
  margin-top: 10%; /* Adjust the value as needed */
}

.carousel .control-arrow {
  display: none;
}

.carousel .control-dots {
  display: none;
}

.carousel .slider-wrapper {
  overflow: hidden;
}

.carousel .slider-wrapper .carousel-slide {
  position: relative;
  height: 100%;
}

.carousel .slider-wrapper .carousel-slide .image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.carousel .slider-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}

.button-wrapper {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-0%, 50%);
}

.search-bar-container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the value to position it below the carousel */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #fff;
  width: 100%;
}

.search-bar {
  width: 300px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
}

.festival-list-container {
  margin-top: 10px; /* Adjust the value as needed */
}

.landingpage {
  margin-top: 20px;
}

button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .search-bar-container {
    top: calc(40% + 10px); /* Adjust the value to position it below the carousel */
    margin-bottom: 0;
    margin-top: 15%;
  }
}

@media (min-width: 769px) {
  .carousel-container {
    height: calc(70vh / 100 * 100vw);
  }

  .carousel .carousel-slider {
    height: 70%;
    margin-top: 15%;
    margin-bottom:
  }

  .search-bar-container {
    top: calc(100% + 10px); /* Adjust the value to position it below the carousel */
  }

  .festival-list-container {
    margin-top: 20px; /* Adjust the value as needed */
  }
}
